import React from "react";
import PropTypes from "prop-types";

class TicketDashboardBubble extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      temporaryTicketValue: null,
      isEditing: false,
      isBubbleHovered: false
    }
    this.toggleBubbleHover = this.toggleBubbleHover.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
    this.updateTemporaryTicketCount = this.updateTemporaryTicketCount.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  toggleBubbleHover() {
    const { isBubbleHovered } = this.state;
    this.setState({ isBubbleHovered: !isBubbleHovered });
  }

  toggleEditing() {
    const { isEditing } = this.state;
    this.setState({
      temporaryTicketValue: this.props.ticket.count,
      isEditing: !isEditing,
      isBubbleHovered: false
    })
  }

  saveChanges() {
    const { updateTicket, ticket } = this.props;
    const { temporaryTicketValue } = this.state;
    updateTicket(ticket, temporaryTicketValue)
    this.toggleEditing();
  }

  updateTemporaryTicketCount(e){
    this.setState({
      temporaryTicketValue: e.target.value||0
    })
  }

  renderSubBubble() {
    const { isEditing, isBubbleHovered } = this.state;
    if(isEditing) {

    }else {
      return (
        <div onClick={this.toggleEditing} style={{cursor: "pointer"}} onMouseEnter={this.toggleBubbleHover} onMouseLeave={this.toggleBubbleHover} className="sg-tickets-dashboard-ticket-type-button">
          {
            isBubbleHovered ?
            <img src="/images/admin/ticket-edit-enabled.svg"/>
            :
            <img src="/images/admin/ticket-edit.svg"/>
          }
        </div>
      )
    }
  }

  renderInnerBubble() {
    const { ticket, participants } = this.props;
    const { temporaryTicketValue, isEditing } = this.state;
    if(isEditing) {
      return (
        <div className="sg-ticket-dashboard-count-section">
          <label> Total Ticket(s) </label>
          <input
            className=" form-control sg-tickets-dashboard-ticket-type-total-input"
            value={temporaryTicketValue||0}
            type="number"
            min={0}
            max={300}
            onChange={this.updateTemporaryTicketCount}
          />
          <div style={{display: "flex"}}>
            <button onClick={this.saveChanges} className="sg-ticket-dashboard-save-button"> Save </button>
            <button onClick={this.toggleEditing} className="sg-ticket-dashboard-save-button"> Cancel </button>
          </div>
        </div>
      )

    }else {
      return (
        <div className="sg-ticket-dashboard-count-section">
          <div className="sg-tickets-dashboard-ticket-type-count">
            { ticket.tickets_registered }/{ ticket.count }
          </div>
        </div>
      )
    }
  }

  renderTicketName(ticket) {
    let newName = ticket.name;
    const names = [
      {slug: 'day1', name: 'Saturday'},
      {slug: 'day2', name: 'Sunday'}
    ]
    let match = names.find(name => name.slug === ticket.slug);
    if (match) {
      newName = match.name
    }
    return newName;
  }

  render() {
    const { ticket} = this.props;
    return (
      <div className="col-xs-6 col-sm-2">
        <div className="sg-tickets-dashboard-ticket-type-container">
          <div className="sg-tickets-dashboard-ticket-type-name">
            {this.renderTicketName(ticket)}
            {/* {ticket.name.replace("Day Program |","").replace(", 2023","")} */}
          </div>
          {this.renderInnerBubble()}
          {this.renderSubBubble()}
        </div>
      </div>
    )
  }
}

export default TicketDashboardBubble;
